/* Editable */


#loader{
	position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 999999;
    overflow: visible;
    background: #f0f5ff url(../images/loader.gif) no-repeat center center;
}
