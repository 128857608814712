.dz-social-icon{
	li{
		display: inline-block;
		
		a{
			height: 45px;
			width: 45px;
			line-height: 45px;
			text-align: center;
			border-radius: var(--border-radius-base);
		}
	}
	
	&.space-10{
		ul{
			margin-left: -5px;
			margin-right: -5px;
			
			li{
				padding-left: 5px;
				padding-right: 5px;
			}
		}
	}
	&.transparent{
		li{
			a{
				background-color: rgba(255, 255, 255, 0.15);
				color: #fff;
				
				&:hover{
					background-color: var(--primary);
				}
			}
		}
	}
}